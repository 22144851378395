<template>
  <div>
    <!-- 菜单使用 -->
    <div class="nangrid flx1" style="margin-top: 100px">
      <template v-for="item in reactiveParams.menuList">
        <div class="menuStyle" :key="item.menuName" v-if="item.mobileUse == 1" @click="more(item)">
          {{ item.menuName }}
        </div>
      </template>
    </div>

    <!-- 预览 -->
    <!-- <div class="preview">
      <div>预览</div>
      <van-image
        width="100"
        height="100"
        fit="cover"
        src="https://img.yzcdn.cn/vant/cat.jpeg"
        @click="imagepreview()"
      />
    </div> -->
  </div>
</template>
<script>
import {
  ref,
  getCurrentInstance,
  onMounted,
  onUpdated,
  provide,
  reactive,
  watch,
  toRefs,
} from "vue";
// 全页面背景色配置
import color from "@/components/componentsJs/color";
import { useRoute, useRouter } from "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { ImagePreview } from "vant";
import { json } from "body-parser";
export default {
  setup() {
    const router = useRouter();
    const useRouteone = useRoute();
    sessionStorage.setItem("organizeId", useRouteone.query.tokens);
    // console.log(useRouteone.query.tokens);
    // v3路由跳转
    // router.push('/household/usercenter')
    color.colormounted();
    const data = ref([{ data: 123456 }]);
    const configurationList = ref([]);
    const reactiveParams = reactive({
      menuList: [],
    });
    // const
    const { proxy } = getCurrentInstance();
    const images = [
      "https://img.yzcdn.cn/vant/apple-1.jpg",
      "https://img.yzcdn.cn/vant/apple-2.jpg",
    ];
    // pdf
    const pdfUrl =
      "http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf";
    // 图片菜单
    const iconmenu = ref([]);
    iconmenu.value = [
      {
        icon: "apps-o",
        text: "基础数据",
        value: "0",
        url: "/essentialData",
      },
      {
        icon: "envelop-o",
        text: "资讯发布",
        value: "1",
        url: "/issueList",
      },
      {
        icon: "photo-o",
        text: "资讯审核",
        value: "2",
        url: "/InformationReview",
      },
      {
        icon: "contact",
        text: "员工考核",
        value: "/evaluationEmployee",
      },
      {
        icon: "user-circle-o",
        text: "员工统计",
        value: "/staffStatistics",
      },
      {
        icon: "chart-trending-o",
        text: "科队考核",
        value: "/ScientificTeamAssessment",
      },
      {
        icon: "bar-chart-o",
        text: "科队统计",
        value: "/SectionStatistics",
      },
      {
        icon: "ellipsis",
        text: "更多",
        value: "7",
      },
    ];

    // 初始化接口数据
    const DataList = async () => {
      // 获取配置信息
      let list = await proxy.$Api.get(proxy.$generalApi.getAllDatas);
      sessionStorage.setItem(
        "PlatformAttribute",
        JSON.stringify(list.data.data)
      );
      let listSub = await proxy.$Api.get(proxy.$generalApi.getAllData);
      localStorage.setItem("dictionaries", JSON.stringify(listSub.data.data));
      if (list.data.code != 401) {
        // 获取菜单列表信息
        let MenuList = await proxy.$Api.get(proxy.$generalApi.getUserRoleMenu);
        reactiveParams.menuList = MenuList.data.data;
        localStorage.setItem("Mens", JSON.stringify(reactiveParams.menuList));
        proxy.$store.dispatch("MenuList", reactiveParams.menuList);
      }
    };

    function more(item) {
      console.log(item);
      uni.navigateTo({
        url: "/pages/yeskip/index?urls=/secondLeveIndex" + "&id=" + item.id,
      });

      // proxy.$router.push({ path: url });
      // 小程序跳转使用
      // uni.navigateTo使用unijs引入跳转
      // uni.navigateTo({
      //   url: "/pages/yeskip/index?urls=" + "saoma",
      // });

      // 扫码（小程序） ?saoma= saoma
      // 小程序返回分享等操作触发
      // proxy.wx.miniProgram.postMessage({
      //       data: {
      //         action: "message",
      //       },
      //     });
      //   proxy.wx.miniProgram.navigateBack()
      // }

      // 信息传递（app的信息传递）
      // uni.postMessage({
      //   data: {
      //     value: value,
      //     value: url,
      //   },
      // });

      // （菜单选择使用）
      // if (value == 7) {
      // webuni.navigateTo({
      //   url: "/pages/yeskip/meinfo?urls=" + "123",
      // });
      // }
    }
    // 图片预览
    function imagepreview() {
      let imgarray = [
        "https://img.yzcdn.cn/vant/cat.jpeg",
        "https://img.yzcdn.cn/vant/apple-1.jpg",
        "https://img.yzcdn.cn/vant/apple-2.jpg",
      ];
      ImagePreview(imgarray);
    }
    // pdf预览
    function pdfpreview() { }
    onMounted(() => {
      DataList();
    });
    // 开始触发事件
    return {
      ...toRefs(reactiveParams),
      reactiveParams,
      configurationList,
      data,
      images,
      more,
      iconmenu,
      // 图片预览
      imagepreview,
      // pdf预览
      pdfpreview,
      pdfUrl,
      // 配置数据接口
      DataList,
    };
  },
};
</script>
<style lang="scss" scoped>
// 公共
@mixin margin-top($topNumber) {
  margin-top: $topNumber;
}

@mixin border-radius($radiusvalue) {
  border-radius: $radiusvalue;
}

.common {
  width: 90%;
  margin: 0 auto;
}

// 轮播样式
.carousel {
  @extend .common;
  margin-top: 0.8rem;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.2rem;
  }
}

::v-deep(.van-swipe-item) {
  height: 4.5rem !important;
}

// 导航菜单
.nangrid {
  @extend .common;
  @include margin-top(0.3rem);
  @include border-radius(0.2rem);
  overflow: hidden;
}

// 预览
.preview {
  font-size: 0.4rem;
  text-align: center;
}

// 菜单样式
.menuStyle {
  width: 25%;
  text-align: center;
}</style>
